.navigationbar {
    background-color: transparent;
    padding-top: 5vh;
}

.navbarlink {
    color: white;
}

.navbar-light span.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(65,48,40)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    color: #413028ff;
}

.navbar-light button.navbar-toggler {
    border-color: #413028ff;
}

.navbtn {
    margin-left: 20px;
    color: #413028ff;
    background-color: #0066cc00;
    padding: 5px 30px;
    border: solid #413028ff 2px;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius: 50px;
    transition: 1000ms;
    transform: translateY(0);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.navbtn:hover {
    transition: 1000ms;
    padding: 5px 50px;
    transform: translateY(-0px);
    background-color: #ffffff;
    color: #413028ff;
    border: solid 2px #413028ff;
    align-items: center;
}