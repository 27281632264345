.App {
  background-color: #e6e1de;;
  color: #413028ff;
  font-family: "Trebuchet MS", Tahoma, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #413028ff;
  background-color: #e2d5d0;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Noteworthy', sans-serif;
                                                
}


